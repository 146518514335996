import React, { useEffect, useState } from 'react'
import "../index.css";
import '../webqr.css';
import axios from 'axios';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const Discover = () => {
  const { word } = useParams()
  const [checkError, setCheckError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [data, setData] = useState([])
  useEffect(() => {
    let fetchData = async () => {
      try {
        let response = await axios.get(`https://api.zerotag.io/api/qr-code/discover/${word}`)
        console.log("response", response);
        if (response.data.result === "Success") {
          console.log("response product", response.data.data.product);
          setData(response.data.data.product)
        }
        else if (response.data.result === "Error") {
          console.log("Error", response.data.errors);
        }
      }
      catch (error) {
        console.error("error", error);
        setErrorMsg(error.response.data.errors[0].msg);
        setCheckError(true);
      }
    }
    fetchData()
  }, [])

  console.log("errorMsg==", errorMsg);

  return (
    <div id="wrap" className="w-full md:w-[375px] mx-auto">
      <div className="preLoadBox aspect-[4/5] w-full px-4 pb-4 pt-6 pr-4" style={{
        position: 'relative',
        background: 'linear-gradient(359.87deg, #E6DDDB -0.72%, #F9F5ED 102.67%)',
        backdropFilter: 'blur(2px)',
        height: '812px',
      }}>
        <div className='w-[120px] h-[28px] top-[782px]' style={{ fontFamily: 'Omnes Medium', fontSize: "30px", lineHeight: "28px", color: "#3D3B46" }}>Discover</div>
        {(checkError === true) ?
          <div className='h-full flex items-center justify-center'>
            <div className='h-[60%] w-[90%] border-[3px] border-dashed border-gray-300 rounded-2xl flex items-center justify-center '>
              <p className='text-gray-400 font-medium text-lg'>{errorMsg}</p>
            </div>
          </div>
          : <div className="grid grid-cols-2 gap-4 justify-items-center mt-4">
            {data.map(({ thumbnail, Partner, name }) => {
              return (
                <div className='w-[165px] h-[357px] left-[15px] top-[1214px]'
                  style={{
                    // position: "absolute",
                    width: "165px",
                    height: "39px",
                    left: "calc(50 % - 165px / 2 - 90px)",
                    // top: "825px",
                    background: " #FFFFFF",
                    boxShadow: "0px 5px 20px 1px rgba(0, 0, 0, 0.1)",
                    borderRadius: "14px 14px 0px 0px",
                  }}
                >

                  <div
                    className='w-[165px] h-[39px] top-[825px] bg-[#FFFFFF] flex items-center justify-center'
                    // style={{
                    //   left: 'calc(50% - 165px/2 - 90px)',
                    //   boxShadow: "0px 5px 20px 1px rgba(0, 0, 0, 0.1)",
                    //   borderRadius: "14px 14px 0px 0px",
                    // }}
                    style={{
                      position: "absolute",
                      width: "54.34px",
                      height: "20.25px",
                      left: "68.85px",
                      top: "10%",
                      background: `url(${Partner.retailer_logo})`,
                      borderRadius: "2px",
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPositionX: "center",

                    }}
                  >
                    {/* <img className='left-[68.85px] top-[68.85px]rounded-sm' src={Partner.retailer_logo} alt="title_logo" style={{ width: '50%', height: '30px' }} /> */}
                  </div>
                  <div className='w-full h-1/2'>
                    {/* { {!thumbnail ?
                (<div className='w-[165px] h-[150px] top-[864.05px] mt-[6px] rounded-2xl flex items-center justify-center '><p className='text-gray-400 '>Empty</p></div>)
                :  */}
                    <div className='w-[165px] h-[150px] top-[864.05px] mt-[6px] rounded-2xl flex items-center justify-center '
                    style={{
                      position: "absolute",
                      width: "165px",
                      height: "165px",
                      // left: "calc(50% - 165px/2 - 90px)",
                      top: "12.2%",
                      background: `url(${thumbnail})`,
                      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                      borderRadius: "0px",
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }}
                    ></div>
                    {/* <img className='w-[165px] h-[165px] top-[864.05px]'
                      style={{
                        left: 'calc(50% - 165px/2 - 90px)',
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                        position: "absolute",
                        width: "165px",
                        height: "165px",
                        top: "12.2%",
                      }} src={thumbnail} /> */}
                    {/* }  */}
                  </div>
                  <div
                    className='w-[165px] h-[122px] px-3 pt-2 top-[1029px] bg-[#FFFFFF] '
                    style={{
                      position: "absolute",
                      width: "165px",
                      height: "122px",
                      left: "calc(50 % - 165px / 2 - 90px)",
                      top: "33.1%",
                      background: "#FFFFFF",
                      boxShadow: "0px 5px 20px 1px rgba(0, 0, 0, 0.1)",
                      borderRadius: "0px 0px 12px 12px"
                    }}
                  >
                    {/* <div className='w-[141px] h-[88px] left-[27px] top-[1039px]'> */}
                    <Typography sx={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Inter', fontStyle: 'normal', color: '#3D3B46', textTransform: 'capitalize' }}>{name}</Typography>
                    <Typography sx={{ fontSize: "14px", fontFamily: 'Omnes', fontStyle: 'normal', color: '#3D3B46', textTransform: 'capitalize' }}>{Partner.name}</Typography>
                    {/* </div> */}
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    </div >
  )
}

export default Discover