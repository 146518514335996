import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Error Page</h1>
      <p>Oops! Something went wrong.</p>
      {/* Add your custom error message or content here */}
      <div className='emptyBox'>
        <img src="../Unexpected_Error2.webp" />
      </div>
    </div>
  );
};

export default ErrorPage;