import "./index.css";
import './webqr.css'; // Import the custom CSS file
import { BrowserRouter, Routes, Route, useParams, Navigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import ErrorPage from './ErrorPage'; // Import the custom error page component
import { AppBar, Tab, Tabs, Typography, Box, CircularProgress } from '@mui/material';
import Discover from './components/Discover';
import DiscoverProducts from "./discoverproducts";

const WebQRPage = () => {
  let { id } = useParams();
  const [productData, setProductData] = useState([]);
  const [errors, setErrors] = useState(false);
  const [value, setValue] = useState(0);
  const [outputLinks, setOutputLinks] = useState();
  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState([
    { label: 'Tab 1', content: 'Content for Tab 1' },
    { label: 'Tab 2', content: 'Content for Tab 2' },
    { label: 'Tab 3', content: 'Content for Tab 3' },
  ]);
  const [tabContent, setTabContent] = useState({});

  useEffect(() => {

    setTimeout(() => {
      const labelData = async () => {
        try {
          // const response = await axios.get(`/api/qr-code/web-qr-detail/${id}`);
          const response = await axios.get(`https://api.zerotag.io/api/qr-code/web-qr-detail-product/${id}`);
          // console.log("result-->" + response.data.result);
          if (response.data.result === "Success") {
            // console.log(response.data.data.product);
            setProductData(response.data.data.product);
            const tabData = JSON.parse(response.data.data.product.output_links);
            // console.log("tabData===");
            // console.log(tabData);
            // setOutputLinks(tabData);
            setTabs(tabData);


          } else {
            setErrors(false);
            console.log('2.Else block an error occurred while fetching data.');
          }
          // console.log(response);
        } catch (error) {
          setErrors(true);
          console.error('Catch block error fetching data:', error);

        }
      };
      labelData();
    }, 1);
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLoading(true);
  };
  const handleLoad = () => {
    // console.log("handleload***");
    setLoading(false);
    // console.log("loading=="+loading);
  };
  return (
    <div id="wrap" className="w-full md:w-[375px] mx-auto">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          // scrollButtons="true"
          sx={{
            width: '100%',
            color : "#c7c6c6",
            backgroundColor: productData.tab_bg_color_code ? productData.tab_bg_color_code : "#ffffff",
            '& .MuiTabs-indicator': {
              height: 4, // Increase or decrease the thickness as needed
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
            disableTouchRipple={true}
            disableFocusRipple={true}
              key={index}
              label={tab.tab_name}
              // indicatorColor="secondary"
              sx={{
                color: value === index ? productData.primary_color_code : productData.secondary_color_code,
                fontWeight: index === value ? 600 : 400,
                '&:hover': {
                  color: '#005cb2',
                  
                },
                backgroundColor: productData.tab_bg_color_code ? productData.tab_bg_color_code : "#ffffff",
              }}
            />
            
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index} style={{ height: window.innerHeight }}>
          {loading ? (
            <div className="mx-auto absolute w-32 h-32 left-1/2 transform -translate-x-1/2 top-[29.3%]">
              <img src="../Zerotag-Logo.png" className="object-contain" />
            </div>
          ) : null}
          <iframe
            title={tab.tab_name}
            src={tab.output_links}
            width="100%"
            // height="800px"
            height={window.innerHeight}
            onLoad={handleLoad}
            style={{ display: loading ? 'none' : 'block' }}
            sandbox="allow-same-origin allow-scripts"
          />
        </TabPanel>
      ))}
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<WebQRPage />} />
        <Route path="/discover/:word" element={<Discover />} />
        <Route path="/error" element={<ErrorPage />} /> {/* Route for the custom error page */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;